import React from 'react'
import ScreenSizeWrapper from '../../components/utils/ScreenSizeWrapper'
import DrawerAppBar from '../../components/Header/DrawerAppBar'
import { Element as ScrollElement } from 'react-scroll'
import { navObj } from '../../components/utils/ConstObj'
import MainSection from './MainSection'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import homeD from '../../styles/css/icons/drawer/home_d.png'
import homeF from '../../styles/css/icons/drawer/home_f.png'
import serviceD from '../../styles/css/icons/drawer/service_d.png'
import serviceF from '../../styles/css/icons/drawer/service_f.png'
import euroD from '../../styles/css/icons/drawer/euro_d.png'
import euroF from '../../styles/css/icons/drawer/euro_f.png'
import infoD from '../../styles/css/icons/drawer/info_d.png'
import infoF from '../../styles/css/icons/drawer/info_f.png'
import logo from '../../styles/css/icons/ED.png'

const Index = () => {


  const navObjItems = [
    { id: 'etusivu', name: 'Etusivu', icon: homeD, chosenIcon: homeF },
    { id: 'huoltopalvelut', name: 'Huoltopalvelut', icon: serviceD, chosenIcon: serviceF },
    { id: 'huoltohinnasto', name: 'Huoltohinnasto', icon: euroD, chosenIcon: euroF },
    { id: 'yhteystiedot', name: 'Yhteystiedot', icon: infoD, chosenIcon: infoF },
  ]
  return (
    <div>
      <ScreenSizeWrapper Wrapper={DrawerAppBar}
        color={'red'}
        navObjItems={navObjItems}
        colors={{
          bg: '#E10A1D',
          font: '#565454'
        }}
        phone={''}
        logo={logo}/>
      <ScrollElement name={navObj['0'].id}>
        <ScreenSizeWrapper Wrapper={MainSection}/>
      </ScrollElement>
      <ScrollElement name={navObj['1'].id}>
        <ScreenSizeWrapper Wrapper={Section2}/>
      </ScrollElement>
      <ScrollElement name={navObj['2'].id}>
        <ScreenSizeWrapper Wrapper={Section3} size={'md'}/>
      </ScrollElement>
      <ScreenSizeWrapper Wrapper={Section4}/>
      <ScrollElement name={navObj['3'].id}>
        <ScreenSizeWrapper Wrapper={Section5} size={'false'}/>
      </ScrollElement>
    </div>
  )
}

export default Index