import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'
import NavButton from './NavButton'
import AppContext from '../../utils/AppProvider'

const MediumScreen = ({ navItems, scrollToElement, chosen }) => {
  const { data } = useContext(AppContext)
  return (
    <Stack direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box sx={{ width: '10%' }}>
        <img src={data.logo}
          style={{
            width: '150px',
            height: 'auto',
          }}
          alt='Car'
        />
      </Box>
      <Box sx={{ width: '70%' }}>
        <Stack direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          {navItems?.map((item) => (
            <NavButton item={item} chosen={chosen} scrollToElement={scrollToElement} fontSize={13} key={item.id}/>
          ))}
        </Stack>

      </Box>
      <Box sx={{ width: '0%' }}></Box>
    </Stack>
  )
}

export default MediumScreen