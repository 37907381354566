import React from 'react'
import { FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { FontSize } from '../../utils/ConstObj'

const StyledLabel = styled(InputLabel)(() => ({
  fontSize: FontSize.h8,
  fontFamily: 'PoppinsMedium',
  color: '#000000',
  marginLeft: 4,
  marginBottom: 4,

}))

const RadioButton = ({ data, props }) => {
  const { t } = useTranslation()
  const handleItems = (chosenItem) => {
    data.setValues({ ...data.values, [props.fieldName]: chosenItem.target.value })
  }
  return (
    <div>
      <StyledLabel>{t(props?.label)}</StyledLabel>
      <RadioGroup
        name={props?.fieldName}
        value={data?.values[props?.fieldName]}
        onChange={(e) => handleItems(e)}
      >
        {
          RadioData[props.fieldName].map(item =>
            <FormControlLabel
              value={item.id}
              control={<Radio key={item.text}/>}
              key={item.text}
              label={item.text} />)
        }
      </RadioGroup>

    </div>
  )
}

export default RadioButton


const RadioData = {
  answered: [ {
    id: false,
    text: 'Ei vielä vastattu'
  },{
    id: true,
    text: 'Vastattu'
  }],
}