import React from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

const BoldedTypography = ({ text, size, weight, position = 'center', marginBottom= '20px' }) => {
  const StyledTypographyTitle = styled(Typography)(() => ({
    textAlign: position,
    fontFamily: weight ? weight : 'Poppins',
    fontSize: size,
    textShadow: '#0000001a 0px 10px 50px;',
    marginBottom: marginBottom,
  }))


  return (
    <StyledTypographyTitle>
      {text}
    </StyledTypographyTitle>
  )
}

export default BoldedTypography