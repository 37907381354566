import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import React from 'react'

const SectionButton = ({ text, icon, actionClick, size = 1, sx={}, isSmall }) => {
  const BUTTON_SIZE = {
    1: { img: { width: '300px' }, button: { radius: 20 } },
    2: { img: { width: '200px' }, button: { radius: 10 } },
  }

  const StyledLargeButton2 = styled(IconButton)(() => ({
    border: '1px solid black',
    borderRadius: BUTTON_SIZE[size].button.radius,
    margin: '2vh 1vh',
    boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
    backgroundColor: '#f2f2f2',
    color: 'black',
    '&:hover': {
      backgroundColor: '#fff',
    },
  }))


  return (
    <StyledLargeButton2 aria-label="delete" onClick={() => actionClick()} sx={sx}>
      <img src={icon} style={BUTTON_SIZE[isSmall ? 2: 1].img} alt={text}/>
    </StyledLargeButton2>
  )
}

export default SectionButton