import React, { useContext } from 'react'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import NavButton from './NavButton'
import AppContext from '../../utils/AppProvider'

const XLargeScreen = ({ navItems, scrollToElement, chosen }) => {
  const { data } = useContext(AppContext)
  return (
    <Stack direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box sx={{ width: '20%' }}>
        <img src={data.logo}
          style={{
            width: '260px',
            height: 'auto',
            maxHeight: 90
          }}
          alt='Car'
        />
      </Box>
      <Box sx={{ width: '60%' }}>
        <Stack direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          {navItems?.map((item) => (
            <NavButton key={item.id} item={item} chosen={chosen} scrollToElement={scrollToElement}
              fontSize={16}/>
          ))}
        </Stack>

      </Box>
      <Box sx={{ width: '20%' }}></Box>
    </Stack>
  )
}

export default XLargeScreen