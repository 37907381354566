import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import Divider from '@mui/material/Divider'
import { FontSize } from '../../../components/utils/ConstObj'
import AppText from '../../../components/commons/AppText'
import Location from '../../../styles/css/icons/pesu/icons8-map-marker-80.png'
import Telephone from '../../../styles/css/icons/pesu/blue-call-d.png'
import Email from '../../../styles/css/icons/pesu/icons8-email-80.png'
import FaceBook from '../../../styles/css/icons/pesu/icons8-facebook-80.png'
import Clock from '../../../styles/css/icons/pesu/icons8-clock-80.png'

const IconWithTextList = () => {
  const listInfo = [
    {
      id: 1,
      icon: Location,
      text: 'Teollisuustie 13, 33960 Pirkkala',
      sx: { fontSize: FontSize.h1, color: '#E10A1D' }
    },
    { id: 2, icon: Telephone, text: '041 3116829', sx: { fontSize: FontSize.h1, color: '#E10A1D' } },
    { id: 3, icon: Email, text: 'info@edautohuolto.fi', sx: { fontSize: FontSize.h1, color: '#E10A1D' } },
    { id: 4, icon: FaceBook, text: 'ED Autopesu', sx: { fontSize: FontSize.h1, color: '#E10A1D' } },
    { id: 5, icon: Clock, text: 'ma - pe: 10:00 - 18:00', sx: { fontSize: FontSize.h1, color: '#E10A1D' } },
    { id: 6, icon: Clock, text: 'la - su: sopimuksen mukaan', sx: { fontSize: FontSize.h1, color: '#E10A1D' } },
  ]

  const action = (item) => {

    switch (item.id) {
    case 1: {
      const destinationAddress = '1Teollisuustie 13, 33960 Pirkkala, Finland'
      const googleMapsDirectionsURL = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destinationAddress)}`
      window.open(googleMapsDirectionsURL, '_blank')
      break
    }
    case 2: {
      window.location.href = 'tel:+358 41 3116829'
      break
    }
    case 3: {
      const emailAddress = 'info@edautohuolto.fi'
      const subject = ''
      const body = 'Moi, \n '

      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      window.open(mailtoLink, '_blank')
      break
    }
    case 4: {
      const facebookURL = 'https://www.facebook.com/profile.php?id=100077909300273'
      window.open(facebookURL, '_blank')
      break
    }
    default:
      console.log('No matching case')
      break
    }

  }
  return (
    <List>
      {listInfo.map(item => (
        <React.Fragment key={item.id}>
          <ListItem onClick={() => action(item)} sx={{ cursor: 'pointer' }}>
            <ListItemIcon>
              <img src={item.icon} width={40} alt={item.text}/>
            </ListItemIcon>
            <ListItemText>
              <AppText text={item.text} fontSize={FontSize.h6} color={'#54595f'}/>
            </ListItemText>
          </ListItem>
          <Divider component="li"/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default IconWithTextList