import React, { useEffect, useState } from 'react'
import FormikDataForm from './FormikDataForm'
import priceOfferSchema from './schema/tarjousSchema'
import { animateScroll as scroll } from 'react-scroll'
import { offer } from '../../services/methods'
import { CircularProgress } from '@mui/material'
import AppDialod from '../commons/AppDialod'
import SUCCESS from '../../styles/css/icons/success.png'
import ERROR from '../../styles/css/icons/error.png'
import { styled } from '@mui/material/styles'
import LogoComponent from '../commons/LogoComponent'
import { useRouter } from '../utils/useRoutes'


const StyledCircularProgress = styled(CircularProgress)(() => ({
  color: '#0FA14C',
  alignItems: 'center',
  size: 260
}))


const initData = {
  name: '',
  email: '',
  phone: '',
  model: '',
  register: '',
  klm: '',
  offer: [],
  description: '',
  contactBy: ['puhelimitse']
}

const PriceOfferForm = ({ isSmall }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [msgType, setMsgType] = useState(1)
  const [formData, setFormData] = useState(initData)
  const route = useRouter()
  useEffect(() => {
    scroll.scrollToTop()
  }, [])

  const dialogChild = {
    1: <StyledCircularProgress size={260} thickness={2}/>,
    2: <LogoComponent logo={SUCCESS} name={'success'} />,
    3: <LogoComponent logo={ERROR} name={'error'} />
  }
  const handleSubmit = async (data) => {
    setOpenDialog(true)
    try {
      await offer.createOffer(data)
      setTimeout(() => {
        setMsgType(2)
      }, 1000)

      setTimeout(() => {
        setOpenDialog(false)
        route.push('/autohuolto')
      }, 3000)

    } catch (e) {
      setMsgType(3)
      setTimeout(() => {
        setOpenDialog(false)
        setFormData(data)
      }, 3000)
    }

  }

  return (
    <>
      <AppDialod open={openDialog}>
        {dialogChild[msgType]}
      </AppDialod>
      <FormikDataForm
        initData={formData}
        isSmall={isSmall}
        label={'Tarjouspyyntö'}
        submitSchema={priceOfferSchema}
        handleSubmit={handleSubmit}>
        <FormikDataForm.Input label={'tarjous.name'} fieldName={'name'}/>
        <FormikDataForm.Input label={'tarjous.email'} fieldName={'email'}/>
        <FormikDataForm.Input label={'tarjous.phone'} fieldName={'phone'}/>
        <FormikDataForm.Input label={'tarjous.model'} fieldName={'model'}/>
        <FormikDataForm.Input label={'tarjous.register'} fieldName={'register'}/>
        <FormikDataForm.Input label={'tarjous.klm'} fieldName={'klm'}/>
        <FormikDataForm.Select label={''} fieldName={'offer'}/>
        <FormikDataForm.Input label={'tarjous.description'} fieldName={'description'} multiline={true}/>
        <FormikDataForm.Select label={'tarjous.contactBy'} fieldName={'contactBy'}/>
        <FormikDataForm.BackButton/>
        <FormikDataForm.SubmitButton label={'tarjous.send'}/>


      </FormikDataForm>
    </>
  )
}

export default PriceOfferForm