import React from 'react'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'

const LogoComponent = ({ logo, name }) => {
  return (
    <Stack direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box sx={{ width: '100%' }}>
        <img src={logo}
          style={{
            width: '20vh',
            height: 'auto',
            minWidth: '100px'
          }}
          alt={name}
        />
      </Box>
    </Stack>
  )
}

export default LogoComponent