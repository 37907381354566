import React from 'react'
import Box from '@mui/material/Box'
import logo from '../../../styles/css/icons/ED.png'
import { Stack } from '@mui/material'
import NavButton from './NavButton'

const SmallScreen = ({ navItems, scrollToElement, chosen }) => {
  return (
    <Stack direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box sx={{ width: '10%' }}>
        <img src={logo}
          style={{
            width: '100px',
            height: 'auto',
          }}
          alt='Car'
        />
      </Box>
      <Box sx={{ width: '70%' }}>
        <Stack direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          {navItems?.map((item) => (
            <NavButton item={item} chosen={chosen} scrollToElement={scrollToElement} fontSize={10} key={item.id}/>
          ))}
        </Stack>

      </Box>
      <Box sx={{ width: '0%' }}></Box>
    </Stack>
  )
}

export default SmallScreen