import React from 'react'
import { Container, Grid, Stack, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import CarImage from '../../../styles/css/icons/car_1.png'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { Sizes } from '../../../components/utils/Enums'
import LevelTwoTitle from '../../../components/commons/LevelTwoTitle'
import LargeButton from '../../../components/commons/LargeButton'
import { useRouter } from '../../../components/utils/useRoutes'
import Ok from '../../../styles/css/icons/huolto/ok.png'
import SellD from '../../../styles/css/icons/huolto/sell_d.png'

const Index = ({ screenSize, isSmall }) => {

  const route = useRouter()

  const fontSize = {
    [Sizes['XS']]: '1rem',
    [Sizes['SM']]: '1.2rem',
    [Sizes['MD']]: '1.2rem',
    [Sizes['LG']]: '1.5rem',
    [Sizes['XL']]: '1.5rem',
  }
  const StyledTypography = styled(Typography)(() => ({
    color: '#54595f',
    textAlign: 'center',
    fontFamily: 'Poppins',
    maxWidth: '800px',
    fontSize: fontSize[screenSize]
  }))

  return (
    <div>
      {
        screenSize === Sizes.XS ? <></> : <Toolbar/>
      }
      <Container maxWidth="xl">
        <Box sx={{
          height: `${window.innerHeight * 0.6}px`,
          overflow: 'hidden'
        }}>
          <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center">
            <LevelTwoTitle isSmall={isSmall} label={'Kattavat ja luotettavat autohuoltopalvelut'}/>
            <StyledTypography variant={'h5'}>Meillä onnistuu autojen määräaikaishuollot, öljynvaihdot,
                            jakohihnan vaihdot, ohjauskulmien säädöt, jarrupalojen ja -levyjen vaihdot,
                            automaattivaihteiston öljynvaihdot, vikadiagnostiikka ja
                            ilmastointihuolto</StyledTypography>
            <img src={CarImage}
              style={{
                width: '100%',
                maxWidth: '800px',
              }}
              alt="Car"
            />

          </Stack>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: 'transparent' }}
        >
          <LargeButton key={'tarjours'} icon={SellD} text={'Tarjouspyyntö'}
            actionClick={() => route.push('/autohuolto/tarjouspyynto')}/>
          <LargeButton key={'palaute'} icon={Ok} text={'Anna palautetta'}
            actionClick={() => route.push('/autohuolto/annapalaute')}/>
        </Grid>
      </Container>

    </div>
  )
}

export default Index