import React, { createContext, useState } from 'react'
import HuoltoLogo from '../../styles/css/icons/ED.png'
import PesuLogo from '../../styles/css/icons/ED-pesu.png'
import { Container } from '@mui/material'

const AppContext = createContext({})

export const AppProvider = ({ children, size, color }) => {

  const initData = {
    red: {
      name: 'red',
      logo: HuoltoLogo,
      masterColor: '#E10A1D',
      fontColor: '#565454',
      info: {
        contactphone: '358407590506',
      }
    },
    blue: {
      name: 'blue',
      logo: PesuLogo,
      masterColor: '#008dbc',
      fontColor: '#000',
      info: {
        contactphone: '358413116829',
      }
    }
  }

  const [data] = useState(initData[color])

  return (
    <AppContext.Provider value={{ data }}>
      <Container maxWidth={size} >
        {children}
      </Container>
    </AppContext.Provider>
  )
}

export default AppContext
