import * as i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import english from './en/en.json'
import finnish from './fi/fi.json'


const resources = {
  fi: {
    translation: finnish
  },
  en: {
    translation: english
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fi',
    fallbackLng: 'fi',

    interpolation: {
      escapeValue: false
    }
  }).catch(e => console.log(e.message))

export default i18n