import React from 'react'
import styled from '@emotion/styled'
import { InputLabel, TextField } from '@mui/material'
import { FontSize } from '../../utils/ConstObj'
import { useTranslation } from 'react-i18next'

const StyledTextField = ({ data, props }) => {
  const { t } = useTranslation()
  const StyledLabel = styled(InputLabel)(() => ({
    fontSize: FontSize.h8,
    fontFamily: 'PoppinsMedium',
    color: '#000000',
    marginLeft: 4,
    marginBottom: 4,

  }))

  const style = {
    maxWidth: 800,
    fontSize: FontSize.h5,
    fontFamily: 'Poppins',
    borderRadius: 15,
    margin: 0,
    padding: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px dashed #000',
      },
      '&:onFocus fieldset': {
        border: '1px dashed #000',
      },
      '&:hover fieldset': {
        border: '1px dashed #000',
      },
      '&.Mui-focused fieldset': {
        border: '1px dashed #000',
      },
    },

  }

  return (
    <div style={{ marginBottom: 1 }}>
      <StyledLabel>{t(props?.label)}</StyledLabel>
      <TextField
        fullWidth
        type={props.type ? props.type : 'text'}
        disabled={props.disabled}
        id={props?.fieldName}
        name={props?.fieldName}
        value={data?.values[props?.fieldName]}
        onChange={data?.handleChange}
        onBlur={data?.handleBlur}
        error={data?.touched[props?.fieldName] && Boolean(data?.errors[props?.fieldName])}
        helperText={data?.touched[props?.fieldName] && data?.errors[props?.fieldName]}
        sx={style}
        InputProps={{
          style: {
            fontSize: FontSize.h7,
            fontFamily: 'Poppins',
            borderRadius: 15,
            padding: props.multiline ? 10 : 2
          },
        }}
        multiline={props.multiline}
        rows={props.multiline ? 10 : 1}
      >
      </TextField>
    </div>
  )
}

export default StyledTextField