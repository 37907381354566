import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Autohuolto from './Autohuolto'
import EdAutoGroup from './EdAutoGroup'
import Autopesu from './Autopesu'
import OfferRequest from './OfferRequest'
import Feedback from './Feedback'
import OneOfferPage from './OneOfferPage'
import LoggedOffer from './LoggedOffer'
import LoggedFeedbacks from './LoggedFeedbacks'
import FeedbackPage from './FeedbackPage'
import LoginPage from './LoginPage'
import AdminPage from './AdminPage'

const Main = () => {

  return (
    <Routes>
      <Route path={'/'} element={<EdAutoGroup/>}/>
      <Route path={'/autohuolto'} element={<Autohuolto/>}/>
      <Route path={'/autohuolto/tarjouspyynto'} element={<OfferRequest/>}/>
      <Route path={'/autohuolto/annapalaute'} element={<Feedback/>}/>
      <Route path={'/autopesu'} element={<Autopesu/>}/>
      <Route path={'/edautogrouptarjoukset'} element={<LoggedOffer/>}/>
      <Route path={'/edautogrouptarjoukset/:id'} element={<OneOfferPage/>}/>
      <Route path={'/edautogrouppalautteet'} element={<LoggedFeedbacks/>}/>
      <Route path={'/edautogrouppalautteet/:id'} element={<FeedbackPage/>}/>
      <Route path={'/edautogrouplogin'} element={<LoginPage/>}/>
      <Route path={'/admin'} element={<AdminPage/>}/>
    </Routes>
  )
}

export default Main