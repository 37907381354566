import React from 'react'
import ScreenSizeWrapper from '../../components/utils/ScreenSizeWrapper'
import Section5 from '../Autohuolto/Section5'
import FeedbackForm from '../../components/form/FeedbackForm'
import LogoComponent from '../../components/commons/LogoComponent'
import Logo from '../../styles/css/icons/logo.png'

const Index = () => {
  return (
    <div>
      <LogoComponent logo={Logo} name={'car'}/>
      <ScreenSizeWrapper Wrapper={FeedbackForm}/>
      <ScreenSizeWrapper Wrapper={Section5} size={'false'}/>
    </div>
  )
}

export default Index