import AppDialod from '../../components/commons/AppDialod'
import FormikDataForm from '../../components/form/FormikDataForm'
import credentialSchema from '../../components/form/schema/credentialSchema'
import LogoComponent from '../../components/commons/LogoComponent'
import SUCCESS from '../../styles/css/icons/success.png'
import ERROR from '../../styles/css/icons/error.png'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'
import { clearLocalStorageByKey, login, setLocalStorage, userAuthentication } from '../../services/methods'
import { useRouter } from '../../components/utils/useRoutes'
import { animateScroll as scroll } from 'react-scroll'

const initialCredential = {
  username: '',
  password: ''
}

const StyledCircularProgress = styled(CircularProgress)(() => ({
  color: '#0FA14C',
  alignItems: 'center',
  size: 260
}))

const LoginForm = ({ url }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [msgType, setMsgType] = useState(1)
  const [formData, setFormData] = useState(initialCredential)
  const route = useRouter()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await userAuthentication()
        if (user === null) {
          scroll.scrollToTop()
        } else {
          route.push('/admin')
        }
      } catch (error) {
        // Handle error if userAuthentication fails
        console.error('Error in user authentication:', error)
      }
    }

    fetchData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSubmit = async (data) => {
    clearLocalStorageByKey('USER')
    setOpenDialog(true)
    try {
      const user =  await login.login(data)
      setTimeout(() => {
        setMsgType(2)
      }, 1000)

      setTimeout(() => {
        setOpenDialog(false)
        if (user && user.token) {
          setLocalStorage('USER', user)
          route.push(url ? url : '/admin')
        }

      }, 3000)

    } catch (e) {
      setMsgType(3)
      setTimeout(() => {
        setOpenDialog(false)
        setFormData(data)
      }, 3000)
    }

  }

  const dialogChild = {
    1: <StyledCircularProgress size={260} thickness={2}/>,
    2: <LogoComponent logo={SUCCESS} name={'success'} />,
    3: <LogoComponent logo={ERROR} name={'error'} />
  }

  return (
    <div>
      <AppDialod open={openDialog}>
        {dialogChild[msgType]}
      </AppDialod>
      <FormikDataForm
        initData={formData}
        label={'ED Group Oy kirjautuminen sivu'}
        schema={credentialSchema}
        handleSubmit={handleSubmit}>
        <FormikDataForm.Input label={'login.username'} fieldName={'username'}/>
        <FormikDataForm.Input label={'login.password'} fieldName={'password'}/>
        <FormikDataForm.SubmitButton label={'Kirjaudu'}/>
      </FormikDataForm>
    </div>
  )
}

export default LoginForm
