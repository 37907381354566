import React from 'react'
import ScreenSizeWrapper from '../../components/utils/ScreenSizeWrapper'
import MainPage from './MainPage'

const Index = () => {
  return (
    <ScreenSizeWrapper Wrapper={MainPage} size={'false'}/>
  )
}

export default Index