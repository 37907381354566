import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { useFormik } from 'formik'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import StyledTextField from './components/StyledTextField'
import { useRouter } from '../utils/useRoutes'
import { Container } from '@mui/material'
import LevelOneTitle from '../commons/LevelOneTitle'
import CheckBoxList from './components/CheckBoxList'
import LargeButton from '../commons/LargeButton'
import Send from '../../styles/css/icons/huolto/send_d.png'
import Back from '../../styles/css/icons/huolto/back_d.png'
import RadioButton from './components/RadioButton'
import Rating from '@mui/material/Rating'
import RadioGroupRating from './components/RadioGroupRating'

const FormikContext = createContext({})

const FormikDataForm = (props) => {
  const { handleSubmit, label, isSmall, initData, submitSchema, } = props
  const targetDivRef = useRef(null)

  const formik = useFormik({
    initialValues: initData,
    validationSchema: submitSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  useEffect(() => {
    formik.setValues(initData)
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData])
  const externalData = useMemo(() => ({ formik }), [formik])

  return (
    <FormikContext.Provider value={externalData}>
      <Container maxWidth="xl" sx={{ marginTop: '2vh' }}>
        <LevelOneTitle isSmall={isSmall} label={label} position={'left'}/>
        <Box component={'form'}>
          {props.children}
        </Box>
      </Container>

    </FormikContext.Provider>
  )
}

const FormComponentWrapper = (props) => {
  const { Component, ...other } = props
  return (
    <Box sx={{
      padding: '10px',
      height: '100%',
      overflow: 'hidden'
    }}>
      <Component {...other}/>
    </Box>
  )
}
const CommonButtonWrapper = (props) => {
  const { Component, ...other } = props
  return (
    <Component {...other} />
  )
}


const InputFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<StyledTextField data={formik} props={props}/>)
}
const SelectFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<CheckBoxList data={formik} props={props}/>)
}

const RadioButtonFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<RadioButton data={formik} props={props}/>)
}
const RatingComponentFormik = (props) => {
  const { formik } = useContext(FormikContext)
  return (<RadioGroupRating data={formik} props={props}/>)
}

const SubmitButtonFormik = (props) => {
  const { t } = useTranslation()
  const { formik } = useContext(FormikContext)
  const { label } = props
  return (
    <LargeButton
      icon={Send}
      actionClick={formik.handleSubmit}
      text={t(label)} size={2}
    />
  )
}
const CommonButton = (props) => {
  const { t } = useTranslation()
  const { label, onClick } = props
  return (
    <LargeButton icon={Send} actionClick={() => onClick()} text={t(label)} size={2} disabled={props.disabled}/>
  )
}
const BackButtonFormik = () => {
  const { t } = useTranslation()
  const route = useRouter()
  return (
    <LargeButton icon={Back} actionClick={() => route.back()} text={t('tarjous.back')} size={2}/>
  )
}


const Input = (props) => <FormComponentWrapper {...props} Component={InputFormik}/>
const Select = (props) => <FormComponentWrapper {...props} Component={SelectFormik}/>
const AppRadioButton = (props) => <FormComponentWrapper {...props} Component={RadioButtonFormik}/>
const RatingComponent = (props) => <FormComponentWrapper {...props} Component={RatingComponentFormik}/>
const SubmitButton = (props) => <CommonButtonWrapper {...props} Component={SubmitButtonFormik}/>
const FormButton = (props) => <CommonButtonWrapper {...props} Component={CommonButton}/>
const BackButton = (props) => <CommonButtonWrapper {...props} Component={BackButtonFormik}/>


FormikDataForm.Input = Input
FormikDataForm.Select = Select
FormikDataForm.RadioButton = AppRadioButton
FormikDataForm.Rating = RatingComponent
FormikDataForm.SubmitButton = SubmitButton
FormikDataForm.FormButton = FormButton
FormikDataForm.BackButton = BackButton
export default FormikDataForm