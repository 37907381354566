import React from 'react'
import { FontSize } from '../utils/ConstObj'
import BoldedTypography from './AppTypography'

const LevelOneTitle = ({ label, isSmall, position }) =>
  <BoldedTypography
    shadow={true}
    text={label}
    size={isSmall ? FontSize.h4 : FontSize.h1}
    weight={'PoppinsBold'}
    position={position}/>

export default LevelOneTitle