import React from 'react'
import ScreenSizeWrapper from '../../components/utils/ScreenSizeWrapper'
import AllOffers from './AllFeedbacks'
import DrawerAppBar from '../../components/Header/DrawerAppBar'
import logo from '../../styles/css/icons/ED-pesu.png'
import AllFeedbacks from './AllFeedbacks'

const Index = () => {
  return (
    <div>
      <ScreenSizeWrapper Wrapper={DrawerAppBar}
        navObjItems={[]}
        color={'red'}
        colors={{
          bg: '#008dbc',
          font: '#000'
        }}
        phone={''}
        logo={logo}/>
      <ScreenSizeWrapper Wrapper={AllFeedbacks}/>
    </div>
  )
}

export default Index