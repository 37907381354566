import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import styled from '@emotion/styled'
import AppContext from '../../utils/AppProvider'

const DrawerButton = ({ item, click, chosen }) => {
  const { data } = useContext(AppContext)
  const StyledTypography = styled(Typography)(() => ({
    fontSize: 8,
    color: data.fontColor,
    margin: 0,
    padding: 0,
    textTransform: 'none',

  }))

  return (
    <Button variant="outlined"
      key={item.id}
      onClick={() => click(item)}
      style={{ padding: 0, marginBottom: 10, marginTop: 10, border: 'none' }}>

      <Box style={{ maxWidth: 50, margin: 0, padding: 0 }}>
        <img src={chosen.id === item.id ? item.chosenIcon : item.icon} width={25} alt={item.id}/>
        <StyledTypography>{item.name}</StyledTypography>
      </Box>
    </Button>
  )
}

export default DrawerButton