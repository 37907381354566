import './App.css'
import React from 'react'
import Main from './pages/Main'
import './styles/css/fonts.css'
import { HashRouter } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Main/>
      </HashRouter>
    </div>
  )
}

export default App
