import React from 'react'
import { Grid } from '@mui/material'
import AppText from '../../../components/commons/AppText'
import { FontSize } from '../../../components/utils/ConstObj'
import IconWithTextList from './IconWithTextList'

const Index = ({ isSmall }) => {
  return (
    <div style={{ marginBottom: isSmall ? '100px' : '150px' }}>
      <Grid container columns={8}>
        <Grid item md={isSmall ? 8 : 3} sx={{ marginBottom: '50px', padding: '10px 20px' }}>
          <AppText text={'Ed autopesu'} isSmall={isSmall} fontSize={FontSize.h4}/>
          <IconWithTextList isSmall={isSmall}/>

        </Grid>
        <Grid item md={isSmall ? 8 : 5}>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d84037.27414762968!2d23.48362607344025!3d61.41218358191157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468ed97447ef3d59%3A0xdaa25ff8ca9f4d68!2sTeollisuustie%2013%2C%2033960%20Pirkkala!5e0!3m2!1sen!2sfi!4v1712357751494!5m2!1sen!2sfi"
            width="100%"
            height="600"
            title='edAutopesu'
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>

        </Grid>
      </Grid>
    </div>
  )
}

export default Index