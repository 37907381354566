import React from 'react'
import styled from '@emotion/styled'
import { keyframes, Paper } from '@mui/material'
import AppText from '../../../components/commons/AppText'
import { FontSize } from '../../../components/utils/ConstObj'

const InfoIconBox = ({ title, text, icon }) => {

  const SlidToTop = keyframes`
        0% {
            -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
       
    `
  const StyledBox = styled(Paper)(() => ({
    textAlign: 'start',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px;',
    border: '1px solid black',
    borderRadius: 10,
    padding: '60px 20px',
    maxWidth: 220,
    margin: 20,
    animation: `${SlidToTop} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
  }))


  return (
    <StyledBox>
      <img src={icon} alt={text}/>
      <AppText fontSize={FontSize.h5} text={title}/>
      <AppText text={text} fontSize={FontSize.h7} color={'#54595f'}/>
    </StyledBox>
  )
}

export default InfoIconBox

