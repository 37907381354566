import React from 'react'
import IconTypo from '../../../components/commons/IconTypo'
import Tool from '../../../styles/css/icons/huolto/icons8-tools-80.png'
import Oil from '../../../styles/css/icons/huolto/icons8-engine-oil-80.png'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import styled from '@emotion/styled'
import LevelOneTitle from '../../../components/commons/LevelOneTitle'

const Index = ({ isSmall }) => {

  const DashedDivider = styled(Divider)({
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderBottomColor: 'black'

  })

  return (
    <div style={{ marginBottom: isSmall ? '100px' : '150px' }}>
      <LevelOneTitle isSmall={isSmall} label={'Huoltohinnasto'}/>
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="center">
        <IconTypo icon={Tool} fontSize={24} text={'Tuntiveloitus: Henkilöautot 90 € / Pakettiautot 95 €'}
          isSmall={isSmall}/>
      </Grid>
      <DashedDivider/>
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="center">
        <IconTypo icon={Oil} fontSize={24} text={'Öljynvaihtotyö: Alkaen 40 €'} isSmall={isSmall}/>
      </Grid>

    </div>
  )
}

export default Index