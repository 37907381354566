export const FontSize = {
  h1: 40,
  h2: 36,
  h3: 32,
  h4: 28,
  h5: 24,
  h6: 18,
  h7: 16,
  h8: 14,
  h9: 12,
}


export const navObj = [
  { id: 'etusivu', name: 'Etusivu' },
  { id: 'huoltopalvelut', name: 'Huoltopalvelut' },
  { id: 'huoltohinnasto', name: 'Huoltohinnasto' },
  { id: 'yhteystiedot', name: 'Yhteystiedot' }
]

export const pesuNavObj = [
  { id: 'etusivu', name: 'Etusivu' },
  { id: 'pesupalvelut', name: 'Pesupalvelut' },
  { id: 'pesuhinnasto', name: 'Pesuhinnasto' },
  { id: 'yhteystiedot', name: 'Yhteystiedot' }
]


