import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import AppContext from '../../utils/AppProvider'

const NavButton = ({ item, chosen, scrollToElement, fontSize }) => {
  const { data } = useContext(AppContext)
  const StyledButton = styled(Button)(() => ({
    color: data.fontColor,
    textTransform: 'none',
    fontSize: fontSize,
    fontFamily: 'PoppinsMedium',
    borderBottom: '1px dotted white',
    borderRadius: 0,
    padding: '0px 10px',
    '&:hover': {
      borderBottom: `1px dotted ${data.masterColor}`,
    },

  }))
  return (
    <StyledButton
      key={item.id}
      onClick={() => scrollToElement(item)}>
      <img src={chosen.id === item.id ? item.chosenIcon : item.icon}
        alt={item.id} width={25}
        style={{ marginBottom: 5, marginRight: 5 }}/>
      {item.name}
    </StyledButton>
  )
}

export default NavButton