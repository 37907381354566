import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { offer } from '../../services/methods'
import { Card, CardContent, CircularProgress, List, ListItem, ListItemText, Toolbar } from '@mui/material'
import BoldedTypography from '../../components/commons/AppTypography'
import { FontSize } from '../../components/utils/ConstObj'
import FormikDataForm from '../../components/form/FormikDataForm'
import { useRouter } from '../../components/utils/useRoutes'
import { animateScroll as scroll } from 'react-scroll'
import AppDialod from '../../components/commons/AppDialod'
import LogoComponent from '../../components/commons/LogoComponent'
import SUCCESS from '../../styles/css/icons/success.png'
import ERROR from '../../styles/css/icons/error.png'
import { styled } from '@mui/material/styles'
import OfferAnswerSchema from '../../components/form/schema/OfferAnswerSchema'

const StyledCircularProgress = styled(CircularProgress)(() => ({
  color: '#0FA14C',
  alignItems: 'center',
  size: 260
}))

const initData = {
  answered: false,
  answerDes: ''
}

const OfferPage = () => {
  const { id } = useParams()
  const [offerData, setOfferData] = useState({})
  const [answer, setAnswer] = useState(initData)
  const [loading, setLoading] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const [msgType, setMsgType] = useState(1)
  const route = useRouter()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await offer.getOffer(id, offerData)
        setOfferData(data)
        setAnswer( {
          answered:  data?.answered ? data.answered : false,
          answerDes:  data?.answerDes ? data.answerDes : ''
        })
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    fetchData().then()
    scroll.scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  function getListItem(label, data) {
    return <ListItem>
      <ListItemText
        primary={<BoldedTypography
          text={label}
          size={FontSize.h6}
          marginBottom={'0px'}
          position={'left'}
          weight={'PoppinsMedium'}/>}
        secondary={<BoldedTypography
          text={data}
          marginBottom={'0px'}
          size={FontSize.h7}
          position={'left'}/>}/>
    </ListItem>
  }

  const handleSubmit = async (data) => {
    setOpenDialog(true)
    try {
      await offer.updateOffer(offerData.id, data)
      setTimeout(() => {
        setMsgType(2)
      }, 1000)

      setTimeout(() => {
        setOpenDialog(false)
        route.push('/edautogrouptarjoukset')
      }, 3000)

    } catch (e) {
      setMsgType(3)
      setTimeout(() => {
        setOpenDialog(false)
        setOfferData(data)
      }, 3000)
    }

  }

  const dialogChild = {
    1: <StyledCircularProgress size={260} thickness={2}/>,
    2: <LogoComponent logo={SUCCESS} name={'success'} />,
    3: <LogoComponent logo={ERROR} name={'error'} />
  }

  const joinArray = (arrayToJoin = []) => {
    if (arrayToJoin.length > 0) {
      return arrayToJoin.join(', ')
    }
    return ''
  }

  return (
    <div>
      <Toolbar />
      {loading ? <div>Loading...</div> : (
        <Card style={{ borderRadius: '10px', padding: '10px' }}>
          <CardContent>
            <BoldedTypography text={'Tarjouspyynnöt tiedot'} size={FontSize.h3} position={'left'}
              weight={'PoppinsBold'}/>
            <List>
              {getListItem('Nimi', offerData.name)}
              {getListItem('Malli ja vuosi:', offerData.model)}
              {getListItem('Rekisterinumero', offerData.register)}
              {getListItem('Kilometrilukema', offerData.klm + ' tkm')}
              {getListItem('Palvelu', joinArray(offerData?.offer))}
              {getListItem('Kuvaus', offerData.description)}
              {getListItem('Saa ottaa minuun yhteyttä', joinArray(offerData?.contactBy))}
              {getListItem('Puhelinnumero', offerData.phone)}
              {getListItem('Sähköposti', offerData.email)}
              <ListItem>
                <AppDialod open={openDialog}>
                  {dialogChild[msgType]}
                </AppDialod>
                <FormikDataForm
                  initData={answer}
                  isSmall={true}
                  label={'Vastaus'}
                  submitSchema={OfferAnswerSchema}
                  handleSubmit={handleSubmit}>
                  <FormikDataForm.RadioButton label={'Onko vastattu'} fieldName={'answered'}/>
                  <FormikDataForm.Input label={'Vastaus asiakkaalle'} fieldName={'answerDes'} multiline={true}/>
                  <FormikDataForm.BackButton/>
                  <FormikDataForm.SubmitButton label={'feedback.send'}/>
                </FormikDataForm>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default OfferPage