import * as Yup from 'yup'

const priceOfferSchema = Yup.object({
  name: Yup.string().required('Nimi on pakollinen kenttä.').min(3, 'Nimi on lyhyt, syötäkää etu- ja sukunimi'),
  email: Yup.string().required('Sähköposti on pakollinen kenttä.').email('Virheellinen sähköpostiosoite.'),
  phone: Yup.string().required('Puhelinnumero on pakollinen kenttä.')
    .test('phone', 'Virheellinen Puhelinnumero.', (value) => {
      const phoneRegExp = /^(?:\+358|0|\+?358|\+?3580|\+?3589)?\s?(?:[1-9]\d){1}(?:\s?\d){6,14}$/
      return phoneRegExp.test(value)
    }),
  model: Yup.string().required('Auton merkki on pakollinen kenttä.'),
  register: Yup.string().required('rekisterinumero on pakollinen kenttä.'),
  klm: Yup.number().required('Kilometrilukema on pakollinen kenttä.')
    .min(1, 'Virheellinen Kilometrilukema.')
    .typeError('Virheellinen Kilometrilukema.'),
  offer: Yup.array()
    .of(Yup.string())
    .min(1, 'Huoltotarpeen kuvaus on pakollinen'),
  contactBy: Yup.array().of(Yup.string()).min(1, 'Yhteydenottotavan valinta on pakollinen.'),
  description: Yup.string()
    .when('offer', {
      is: (value) => value && value.includes('Muu, mikä?'),
      then: (schema) => schema
        .required('Kuvauksen täyttäminen on pakollista, kun valitset muun palvelun!')
        .max(500, 'Kuvauksen pituus ei voi olla yli 500 merkkiä'),
      otherwise: (schema) => schema.
        max(500, 'Kuvauksen pituus ei voi olla yli 500 merkkiä')
    })
})
export default priceOfferSchema