import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'

const StyledDialog = styled(DialogContent)(() => ({
  padding: 30,
  alignItems: 'center'
}))

const AppDialog = ({ handleClose, open, children }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialog>
        {children}
      </StyledDialog>
    </Dialog>
  )
}

export default AppDialog
