import React, { useEffect } from 'react'
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'

const CommonTable = ({ columns, rows, totalRows, onRowClick, onPageChange, onRowPerPage, page, rowsPerPage }) => {
  const StyledTableCell = styled(TableCell)({
    fontFamily: 'Poppins',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textAlign: 'start',
  })
  const StyledTableRow = styled(TableRow)`
      // eslint-disable-next-line

      &.MuiTableRow-hover:hover {
          background-color: #7f7a7a ${'#ffffff'};
      }
  `

  const StyledTableCellHead = styled(TableCell)({
    fontFamily: 'Poppins',
    textAlign: 'start',
    color: '#000',
    borderBottom: `2px solid ${'#000'}`,
    backgroundColor: 'white',
  })

  console.log(columns, rows, totalRows,page, rowsPerPage)

  const style =
      {
        width: '100%',
        overflow: 'hidden',
        marginTop: '4rem ',
        marginBottom: '2rem ',
        border: `2px solid ${'#ffffff'}`,
        borderRadius: '5px',
      }
  return (
    <Paper sx={style} elevation={0}>
      <TableContainer sx={{ maxHeight: 1000 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCellHead key={column.id} style={{ width: column.width, minWidth: column.minWidth }}>
                  {column.label}
                </StyledTableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map( r => {
              return (
                <StyledTableRow
                  hover
                  tabIndex={-1}
                  key={r.id}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onRowClick(r)}>
                  {columns.map((column) => {
                    return (<StyledTableCell key={column.id}>
                      {r[column.id]}</StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={'Rivi per sivu'}
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowPerPage}
      />
    </Paper>
  )
}

export default CommonTable