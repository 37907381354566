import * as React from 'react'
import { styled } from '@mui/material/styles'
import Rating from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import { InputLabel } from '@mui/material'
import { FontSize } from '../../utils/ConstObj'
import { useTranslation } from 'react-i18next'

const StyledRating = styled(Rating)(({ theme }) => ({
  marginBottom: 30,
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}))

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" fontSize={'large'}/>,
    label: 'Very Dissatisfied',
    value: 1,
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" fontSize={'large'}/>,
    label: 'Dissatisfied',
    value: 2,
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" fontSize={'large'}/>,
    label: 'Neutral',
    value: 3,
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" fontSize={'large'}/>,
    label: 'Satisfied',
    value: 4,
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" fontSize={'large'}/>,
    label: 'Very Satisfied',
    value: 5,
  },
}

function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

const StyledLabel = styled(InputLabel)(() => ({
  fontSize: FontSize.h8,
  fontFamily: 'PoppinsMedium',
  color: '#000000',
  marginLeft: 4,
  marginBottom: 4,

}))
export default function RadioGroupRating({ data, props }) {
  const { t } = useTranslation()

  const handleItems = (value) => {
    if (value.target.value) {
      data.setValues({ ...data.values, [props.fieldName]: customIcons[value.target.value].value })
    }
  }
  return (
    <div>
      <StyledLabel>{t(props?.label)}</StyledLabel>
      <br/>
      <StyledRating
        name={props?.fieldName}
        value={data?.values[props?.fieldName] || 3}
        IconContainerComponent={IconContainer}
        onClick={(value) => handleItems(value)}
        getLabelText={(value) => customIcons[value].label}
        highlightSelectedOnly
      />
    </div>

  )
}
