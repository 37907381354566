import React from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

const AppText = ({ fontSize, text, color = '#000000', isBold }) => {
  const StyledTypographyBold = styled(Typography)(() => ({
    textAlign: 'left',
    color: `${color}`,
    fontFamily: isBold ? 'PoppinsMedium' : 'Poppins',
    fontSize: fontSize
  }))
  return (
    <StyledTypographyBold key={'key' + text}> {text} </StyledTypographyBold>
  )
}

export default AppText