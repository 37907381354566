import { GET, PATCH, POST } from './api'

export const setLocalStorage = (key, value) => window.localStorage.setItem(key, JSON.stringify(value))
export const getFromLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key))

export const clearLocalStorageByKey = (key) => window.localStorage.removeItem(key)
export const clearAllLocalStorage = () => window.localStorage.clear()


export const offer = {
  createOffer: async (data) => await POST('api/offer', data),
  getAllOffer: async (page, limit) => await GET(`api/edgroupoy/tarjoukset?page=${page}&limit=${limit}`),
  getOffer: async (id) => await GET(`api/edgroupoy/tarjoukset/${id}`),
  updateOffer: async (id, data) => await PATCH(`api/edgroupoy/tarjoukset/${id}`, data)
}

export const feedBack = {
  createFeedBack: async (data) => await POST('api/feedback', data),
  getAllFeedbacks: async (page, limit) => await GET(`api/edgroupoy/palautteet?page=${page}&limit=${limit}`),
  getFeedback: async (id) => await GET(`api/edgroupoy/palautteet/${id}`),
  updateFeedback: async (id, data) => await PATCH(`api/edgroupoy/palautteet/${id}`, data)
}

export const login = {
  login: async (data) => await POST('api/logintoedgroupoy', data),
  isValid: async (token) => await POST('api/logintoedgroupoy/isValid', { token }),
}


export const userAuthentication = async () => {
  const str = localStorage.getItem('USER')
  const token = JSON.parse(str)
  if (token?.token) {
    const user = await login.isValid(token.token)
    return user
  } else {
    return null
  }
}

export const getDate = (date, get = 'full') => {
  const originalDate = new Date(date)
  const day = String(originalDate.getUTCDate()).padStart(2, '0')
  const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0') // Month is zero-based
  const year = originalDate.getUTCFullYear()

  const formattedDate = `${day}.${month}.${year}`

  return {
    day,
    month,
    year,
    full: formattedDate
  }[get]

}