import React from 'react'
import CarClean from '../../../styles/css/icons/pesu/icons8-shine-80.png'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import styled from '@emotion/styled'
import LevelOneTitle from '../../../components/commons/LevelOneTitle'
import AppText from '../../../components/commons/AppText'
import IconTypo from '../../../components/commons/IconTypo'
import LevelTwoTitle from '../../../components/commons/LevelTwoTitle'

const Index = ({ isSmall }) => {


  const hinnasto = [
    { id: 0, icon: CarClean, text: 'Käsinpesu', price: '20€', secoundText: undefined },
    { id: 1, icon: CarClean, text: 'käsinpesu pakettiautot', price: '25€', secoundText: undefined },
    { id: 2, icon: CarClean, text: 'Tehopesu', price: '70€', secoundText: ['Tehoaa vaikean lian, pien, jarrupölyn, rasvan ja maantiesuolan poistoon.'] },
    { id: 3, icon: CarClean, text: 'Sisäpuhdistus 1', price: '25€', secoundText: ['Sisältää jalkatilan imuroinnin, mattojen ja kojetaulun pesun.',  'Ei sisällä auton ulkopesua.'] },
    { id: 4, icon: CarClean, text: 'Sisäpuhdistus 2', price: '50€', secoundText: ['Sisältää sisätilojen ja takakontin imuroinnin, mattojen ja ikkunoiden pesun, muoviosien puhdistuksen.',  'Ei sisällä auton ulkopesua.'] },
    { id: 5, icon: CarClean, text: 'Sisäpuhdistus 3', price: '169€', secoundText: ['Sisältää täyden sisäpuhdistuksen ja penkkien kemiallisen märkäpesun.',  'Ei sisällä auton ulkopesua.'] },
    { id: 6, icon: CarClean, text: 'Normaalivahaus', price: '89€', secoundText: undefined },
    { id: 7, icon: CarClean, text: 'Kovavahaus', price: '130€', secoundText: undefined },
    { id: 8, icon: CarClean, text: 'Kevytpinnoite', price: '290€', secoundText: undefined },

  ]
  const pakettitarjoukset = [
    { id: 0, icon: CarClean, text: 'Normaalivahaus + sisäpuhdistus', price: '130€', secoundText: undefined },
    { id: 1, icon: CarClean, text: 'Kovavahaus + sisäpuhdistus', price: '170€', secoundText: undefined },
  ]

  const muupalvelut = [
    {
      id: 0,
      icon: CarClean,
      text: 'Nahkapenkkien suojauskäsittely',
      price: '55€',
      secoundText: undefined
    },
    {
      id: 1,
      icon: CarClean,
      text: 'Hajun poisto otsonoinnilla',
      price: '50€',
      secoundText: undefined
    },
    {
      id: 2,
      icon: CarClean,
      text: 'Moottorin pesu (omalla vastuulla)',
      price: '30€',
      secoundText: undefined
    },
    {
      id: 3,
      icon: CarClean,
      text: 'Eläinten karvojen poisto',
      price: 'alk. 40€',
      secoundText: undefined
    },
    {
      id: 5,
      icon: CarClean,
      text: 'Kiillotukset',
      price: 'alk. 200€',
      secoundText: undefined
    },
    {
      id: 6,
      icon: CarClean,
      text: 'Pinnoitukset',
      price: 'alk. 400€',
      secoundText: undefined
    }

  ]

  const DashedDivider = styled(Divider)({
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderBottomColor: 'black'

  })

  return (
    <div style={{ marginBottom: isSmall ? '100px' : '150px' }}>
      <LevelOneTitle isSmall={isSmall} label={'Pesuhinnasto'}/>

      <div style={{ marginTop: '8vh' }}>
        <LevelTwoTitle isSmall={isSmall} label={'Perushinnat'} position={'star'}/>
      </div>
      {
        hinnasto.map(item =>
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconTypo
                icon={item.icon}
                iconSize={{ sm: 25, lg: 50 }}
                fontSize={isSmall ? 18 : 24}
                text={item.text}
                isBold={true}
                key={item.id + 'icon'}
                isSmall={isSmall}/>
              <AppText text={item.price} fontSize={isSmall ? 18 : 24} isBold={true}/>
            </Grid>
            {
              item.secoundText && <div style={{ marginLeft: '3vh' }}>
                {
                  item.secoundText.map(text => <AppText key={item.id + Math.random()} text={text}
                    fontSize={isSmall ? 16 : 18}/>)
                }

                <br/>
              </div>
            }

            <DashedDivider/>
          </>
        )
      }
      <div style={{ marginTop: '8vh' }}>
        <LevelTwoTitle isSmall={isSmall} label={'Pakettitarjoukset'} position={'star'}/>
      </div>

      {
        pakettitarjoukset.map(item =>
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconTypo
                icon={item.icon}
                iconSize={{ sm: 25, lg: 50 }}
                fontSize={isSmall ? 18 : 24}
                text={item.text}
                isBold={true}
                isSmall={isSmall}/>
              <AppText text={item.price} fontSize={isSmall ? 18 : 24} isBold={true}/>
            </Grid>
            <DashedDivider/>
          </>
        )
      }
      <div style={{ marginTop: '8vh' }}>
        <LevelTwoTitle isSmall={isSmall} label={'Muu palvelut'} position={'star'}/>
      </div>

      {
        muupalvelut.map(item =>
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconTypo
                icon={item.icon}
                iconSize={{ sm: 25, lg: 50 }}
                fontSize={isSmall ? 18 : 24}
                text={item.text}
                isBold={true}
                isSmall={isSmall}/>
              <AppText text={item.price} fontSize={isSmall ? 18 : 24} isBold={true}/>
            </Grid>
            <DashedDivider/>
          </>
        )
      }

    </div>
  )
}

export default Index