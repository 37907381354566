import React, { useEffect, useState } from 'react'
import { feedBack, getDate, offer } from '../../services/methods'
import CommonTable from '../../components/commons/CommonTable'
import { useRouter } from '../../components/utils/useRoutes'
import ok from '../../styles/css/icons/success.png'
import notOk from '../../styles/css/icons/error.png'
import { Toolbar } from '@mui/material'
import { FontSize } from '../../components/utils/ConstObj'
import BoldedTypography from '../../components/commons/AppTypography'
import Back from '../../styles/css/icons/huolto/back_d.png'
import LargeButton from '../../components/commons/LargeButton'
import { useTranslation } from 'react-i18next'

const Index = () => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const router = useRouter()
  const { t } = useTranslation()

  useEffect(() => {
    fetchAllOffers(page, rowsPerPage).catch(err => console.error(err))
  }, [page, rowsPerPage])

  const fetchAllOffers = async (pageParameter, limitParameter) => {
    try {
      const fetchedData = await feedBack.getAllFeedbacks(pageParameter, limitParameter)
      setData(fetchedData.results)
      setPage(fetchedData.page)
      setTotalPages(fetchedData.total)
    } catch (e) {
      console.error(e)
    }
  }

  const handleChosenItem = async (item) => {
    router.push(`/edautogrouppalautteet/${item.id}`)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return (
    <>
      <Toolbar/>
      <BoldedTypography text={'Asiakkaiden Palautteet'} size={FontSize.h2} position={'left'}
        weight={'PoppinsBold'}/>
      <CommonTable
        columns={dataForm}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={data.map(s => formattedData(s))}
        totalRows={totalPages}
        onRowClick={handleChosenItem}
        onPageChange={handleChangePage}
        onRowPerPage={handleChangeRowsPerPage}
      />
      <LargeButton icon={Back} actionClick={() => router.back()} text={t('tarjous.back')} size={2}/>
    </>
  )
}

export default Index

const dataForm = [
  {
    id: 'name',
    label: 'Nimi',
    width: 90
  },

  {
    id: 'email',
    label: 'Sähköposti',
    width: 90,
  },
  {
    id: 'phone',
    label: 'Puhelinnumero',
    width: 90,
  },
  {
    id: 'register',
    label: 'Auton rekisterinumero',
    width: 90,
  },
  {
    id: 'createdAt',
    label: 'Luotu',
    width: 90,
  },
  {
    id: 'answered',
    label: 'Vastattu',
    width: 90,
  },
]

const formattedData = (item) => ({
  id: item.id,
  name: item.name,
  email: item.email,
  phone: item.phone,
  register: item.register,
  description: item.description,
  createdAt: item.createdAt ? getDate(item.createdAt) : '-',
  answered: item.answered ? <img src={ok} alt={'answered'} width={20} key={'answered'}/> :
    <img src={notOk} alt={'notAnswered'} width={20} key={'notAnswered'}/>

})
