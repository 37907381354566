import React from 'react'
import ScreenSizeWrapper from '../../components/utils/ScreenSizeWrapper'
import DrawerAppBar from '../../components/Header/DrawerAppBar'
import logo from '../../styles/css/icons/ED-pesu.png'
import LoginForm from './LoginForm'
import { Toolbar } from '@mui/material'

const Index = () => {
  return (
    <div>
      <ScreenSizeWrapper Wrapper={DrawerAppBar}
        navObjItems={[]}
        color={'red'}
        colors={{
          bg: '#008dbc',
          font: '#000'
        }}
        phone={''}
        logo={logo}/>
      <Toolbar />
      <ScreenSizeWrapper Wrapper={LoginForm}/>
    </div>
  )
}

export default Index