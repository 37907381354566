import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'
import AppContext from '../../utils/AppProvider'

const XSmallScreen = () => {
  const { data } = useContext(AppContext)
  return (
    <Stack direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box sx={{ width: '20%' }}>
        <img src={data.logo}
          style={{
            width: '10vh',
            height: 'auto',
            minWidth: '100px'
          }}
          alt="Car"
        />
      </Box>
    </Stack>
  )
}

export default XSmallScreen