import React from 'react'
import { Divider } from '@mui/material'

const DividerBackground = () => {
  return (
    <div>

      {/* Vertical Lines */}
      <Divider orientation="vertical"
        sx={{
          backgroundColor: '#E10A1D',
          position: 'absolute',
          top: 0, left: '15%',
          transform: 'translateX(-50%)',
          height: '100%',
          margin: 0 }} />
      <Divider orientation="vertical"
        sx={{
          backgroundColor: '#008dbc',
          position: 'absolute',
          top: 0, left: '20%',
          transform: 'translateX(-50%)',
          height: '100%',
          margin: 0 }} />
      {/* Horizontal Lines */}
      <Divider sx={{
        backgroundColor: '#E10A1D',
        position: 'absolute',
        top: '15%',
        left: 0,
        width: '100%',
        margin: 0 }} />
      <Divider sx={{
        backgroundColor: '#008dbc',
        position: 'absolute',
        top: '20%',
        left: 0,
        width: '100%',
        margin: 0 }} />
           ´
    </div>
  )
}

export default DividerBackground