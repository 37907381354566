import * as Yup from 'yup'

const requiredStringMinMax = (i,x, field) => Yup.string()
  .min(i, `errors.${field}.tooShort`)
  .max(x, `errors.${field}.tooLong`)
  .required(`errors.${field}.required`)

const credentialSchema = Yup.object({
  username: requiredStringMinMax(2, 40),
  password: requiredStringMinMax(2, 40),
})

export default credentialSchema

