import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import LargeScreen from './LargeScreen'
import MediumScreen from './MediumScreen'
import XLargeScreen from './XLargeScreen'
import XSmallScreen from './XSmallScreen'
import { Sizes } from '../../utils/Enums'
import { scroller } from 'react-scroll'
import DrawerButton from './DrawerButton'
import BlueWhats from '../../../styles/css/icons/pesu/blue-whats-d.png'
import Whats from '../../../styles/css/icons/huolto/whats-d.png'
import AppContext from '../../utils/AppProvider'
import SmallScreen from './SmallScreen'

const Index = ({ screenSize, navObjItems, colors }) => {
  const { data } = useContext(AppContext)
  const [headerShow, setHeaderShow] = useState(true)
  const [chosen, setChosen] = useState(navObjItems[0])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        setHeaderShow(true)
      } else {
        setHeaderShow(false)
      }
    })
  }, [])

  const whatsApp = { id: 'whatsApp', name: 'WhatsApp', icon: data.name === 'red'? Whats : BlueWhats, chosenIcon: data.name === 'red'? Whats : BlueWhats }

  const whatsAppChat = () => {
    window.location.href = `https://wa.me/${data.info.contactphone}`
  }
  const drawer = (
    <Box sx={{ textAlign: 'center', border: 'none', margin: 0, padding: 0 }}>
      {navObjItems && navObjItems.map((item) => (
        <DrawerButton key={item.id}
          item={item}
          click={(data) => scrollToElement(data)} chosen={chosen}/>
      ))}
      <DrawerButton item={whatsApp} click={(data) => whatsAppChat(data)} chosen={chosen}/>
    </Box>
  )


  const scrollToElement = (item) => {
    setChosen(item)
    scroller.scrollTo(item.id, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: screenSize === Sizes.XS ? 0 : -120
    })
  }
  const AppHeader = {
    [Sizes['XS']]: <XSmallScreen/>,
    [Sizes['SM']]: <SmallScreen/>,
    [Sizes['MD']]: <MediumScreen navItems={navObjItems} scrollToElement={scrollToElement} chosen={chosen}/>,
    [Sizes['LG']]: <LargeScreen navItems={navObjItems} scrollToElement={scrollToElement} chosen={chosen}/>,
    [Sizes['XL']]: <XLargeScreen navItems={navObjItems} scrollToElement={scrollToElement} chosen={chosen}/>,
  }

  const heightOfAppHeader = {
    [Sizes['XS']]: '50px',
    [Sizes['SM']]: '70px',
    [Sizes['MD']]: '70px',
    [Sizes['LG']]: '100px',
    [Sizes['XL']]: '100px',
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline/>
      <AppBar component="nav"
        position={screenSize !== Sizes.XS ? 'fixed' : 'relative'}
        style={{
          backgroundColor: headerShow ? '#fff' : 'transparent',
          color: colors.font,
          height: `${heightOfAppHeader[screenSize]}`,
          textTransform: 'none',
          boxShadow: headerShow && screenSize !== Sizes.XS ? '0 5px 5px rgba(0, 0, 0, 0.2) ' : 'none',
          padding: '0px 0px'
        }}>
        {AppHeader[screenSize]}
      </AppBar>
      <nav>
        {navObjItems.length !== 0 && <Drawer
          variant="persistent"
          sx={{ margin: 0, padding: 0 }}
          open={screenSize === Sizes.XS || screenSize === Sizes.SM}
          anchor={'bottom'}
        >
          {drawer}
        </Drawer>}
      </nav>
    </Box>
  )
}

export default Index
