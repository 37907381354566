import React from 'react'
import { useMediaQuery } from '@mui/material'
import { Sizes } from './Enums'
import { AppProvider } from './AppProvider'

const ScreenSizeWrapper = (props) => {
  const { Wrapper, size } = props
  const isXSmallScreen = useMediaQuery('(max-width: 480px)')
  const isSmallScreen = useMediaQuery('(min-width: 481px) and (max-width: 768px)')
  const isMediumScreen = useMediaQuery('(min-width: 769px) and (max-width: 992px)')
  const isLargeScreen = useMediaQuery('(min-width: 993px) and (max-width: 1200px)')
  const isXLargeScreen = useMediaQuery('(min-width: 1201px)')
  const checkScreenSize = () => {
    if (isXSmallScreen) {
      return Sizes['XS']
    } else if (isSmallScreen) {
      return Sizes['SM']
    } else if (isMediumScreen) {
      return Sizes['MD']
    } else if (isLargeScreen) {
      return Sizes['LG']
    } else if (isXLargeScreen) {
      return Sizes['XL']
    } else {
      return 'unknown'
    }
  }

  const screenSize = checkScreenSize()

  const isSmall = [Sizes['XS'], Sizes['SM'], Sizes['MD']].includes(screenSize)
  const newProps = { ...props, isSmall, screenSize }
  return (
    <div style={{ marginBottom: '5%' }}>
      <AppProvider size={size} color={props.color}>
        <Wrapper  {...newProps}   />
      </AppProvider>
    </div>
  )
}

export default ScreenSizeWrapper