import React from 'react'
import { Container, Stack, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import LevelTwoTitle from '../../../components/commons/LevelTwoTitle'
import { Sizes } from '../../../components/utils/Enums'
import CarImage from '../../../styles/css/icons/blue-background.png'

const Index = ({ screenSize, isSmall }) => {


  const fontSize = {
    [Sizes['XS']]: '1rem',
    [Sizes['SM']]: '1.2rem',
    [Sizes['MD']]: '1.2rem',
    [Sizes['LG']]: '1.5rem',
    [Sizes['XL']]: '1.5rem',
  }
  const StyledTypography = styled(Typography)(() => ({
    color: '#54595f',
    textAlign: 'center',
    fontFamily: 'Poppins',
    maxWidth: '800px',
    fontSize: fontSize[screenSize]
  }))

  return (
    <div>
      {
        screenSize === Sizes.XS ? <></> : <Toolbar/>
      }
      <Container maxWidth="xl">
        <Box sx={{
          height: `${window.innerHeight * 0.8}px`,
          overflow: 'hidden'
        }}>
          <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center">
            <LevelTwoTitle isSmall={isSmall} label={'Tarjoamme käsinpesuja kaikenlaisille ajoneuvoille, tehokkaasti ja aina tinkimättä laadusta!'}/>
            <br/>
            <StyledTypography variant={'h5'}>
                            ED Autopesu tarjoaa laajan valikoiman laadukkaita autonpesupalveluita, jotka tekevät autostasi säihkyvän puhtaan ja kiiltävän.
                            Olipa autosi henkilö- tai pakettiauto, meillä on ratkaisu juuri sinun tarpeisiisi.</StyledTypography>
            <img src={CarImage}
              style={{
                width: '100%',
                maxWidth: '800px',
              }}
              alt="Car"
            />

          </Stack>
        </Box>
      </Container>

    </div>
  )
}

export default Index