import React from 'react'
import styled from '@emotion/styled'
import { Paper } from '@mui/material'
import AppText from './AppText'

const IconBox = ({ icon, text, fontSize, isSmall }) => {
  const DemoPaper2 = styled(Paper)(() => ({
    width: isSmall ? '220px' : '300px',
    padding: isSmall ? '10px' : '10px',
    margin: isSmall ? '10px' : '10px',
    textAlign: 'start',
  }))

  return (
    <div key={Math.random().toString()}>
      <DemoPaper2 elevation={0} key={Math.random().toString()}>
        <img src={icon} alt={text} width={80} key={Math.random().toString()}/>
        <AppText text={text} fontSize={fontSize} key={Math.random().toString()}/>
      </DemoPaper2>
    </div>
  )
}

export default IconBox