import React from 'react'
import styled from '@emotion/styled'
import AppText from './AppText'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'

const IconTypo = ({ icon, text, fontSize, isSmall, isBold = false, iconSize={ sm: 100, lg: 100 } }) => {

  const StyledPaper = styled(Box)({
    padding: '10px 0',
    margin: '10px 0',
    textAlign: 'start',
  })

  const Icon = styled('img')({
    marginRight: '5px',
    width: isSmall ? iconSize.sm : iconSize.lg,
  })


  return (

    <StyledPaper>
      <Grid container alignItems="center">
        <Grid item>
          <Icon src={icon} alt={text}/>
        </Grid>
        <Grid item flexGrow={1}>
          <AppText text={text} fontSize={fontSize} isBold={isBold}/>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

export default IconTypo

