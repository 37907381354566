import React from 'react'
import styled from '@emotion/styled'
import { FontSize } from '../utils/ConstObj'
import { IconButton } from '@mui/material'

const LargeButton = ({ text, icon, actionClick, size = 1, bgColor = 'none' }) => {
  const BUTTON_SIZE = {
    1: { img: { width: 60, marginRight: '2vh' }, button: { fontSize: FontSize.h6, radius: 20 } },
    2: { img: { width: 30, marginRight: '1vh' }, button: { fontSize: FontSize.h8, radius: 10 } },
  }

  const StyledLargeButton = styled(IconButton)(() => ({
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: BUTTON_SIZE[size].button.fontSize,
    border: '2px solid #E10A1D',
    padding: '1vh 3vh 1vh 2vh',
    borderRadius: BUTTON_SIZE[size].button.radius,
    textTransform: 'none',
    margin: '2vh 1vh',
    backgroundColor: bgColor,
    color: '#54595f',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  }))


  return (
    <StyledLargeButton
      onClick={() => actionClick()}>
      <img src={icon} style={BUTTON_SIZE[size].img} alt={text} />
      {text}
    </StyledLargeButton>
  )
}

export default LargeButton