import * as Yup from 'yup'

const feedbackSchema = Yup.object({
  name: Yup.string().required('Nimi on pakollinen kenttä.').min(3, 'Nimi on lyhyt, syötäkää etu- ja sukunimi'),
  email: Yup.string().required('Sähköposti on pakollinen kenttä.').email('Virheellinen sähköpostiosoite.'),
  phone: Yup.string().required('Puhelinnumero on pakollinen kenttä.')
    .test('phone', 'Virheellinen Puhelinnumero.', (value) => {
      const phoneRegExp = /^(?:\+358|0|\+?358|\+?3580|\+?3589)?\s?(?:[1-9]\d){1}(?:\s?\d){6,14}$/
      return phoneRegExp.test(value)
    }),
  register: Yup.string().required('rekisterinumero on pakollinen kenttä.'),
  description: Yup.string().max(500, 'Kuvauksen pituus ei voi olla yli 500 merkkiä'),
  contactBy: Yup.array().of(Yup.string()).min(1, 'Yhteydenottotavan valinta on pakollinen.'),
})

export default feedbackSchema