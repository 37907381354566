import React from 'react'
import { Checkbox, FormControlLabel, InputLabel } from '@mui/material'
import { FontSize } from '../../utils/ConstObj'
import AppText from '../../commons/AppText'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const StyledCheckBox = ({ item, isChecked = false, handleChange }) => {
  return (
    <FormControlLabel
      sx={{
        margin: 1,
        fontSize: FontSize.h7,
        fontFamily: 'Poppins',
      }}
      control={
        <Checkbox
          checked={isChecked}
          onChange={() => handleChange(item)}
          inputProps={{ 'aria-label': 'controlled' }}
          name={item}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'red',
            },
          }}
        />
      }
      label={<AppText text={item}/>}
    />
  )
}

const CheckBoxList = ({ data, props }) => {
  const { t } = useTranslation()
  const clonedData = [...data.values[props?.fieldName]]
  const handleItems = (chosenItem) => {
    if (clonedData.includes(chosenItem)) {
      const index = clonedData.indexOf(chosenItem)
      clonedData.splice(index, 1)
    } else {
      clonedData.push(chosenItem)
    }
    data.setValues({ ...data.values, [props.fieldName]: clonedData })
  }
  const errorStyle = {
    border: '1px dashed #d32f2f',
    borderRadius: 10,
    width: '100%'
  }

  const StyledLabel = styled(InputLabel)(() => ({
    fontSize: FontSize.h8,
    fontFamily: 'PoppinsMedium',
    color: '#000000',
    marginLeft: 4,
    marginBottom: 4,

  }))

  return (
    <>
      <StyledLabel>{t(props?.label)}</StyledLabel>
      <div style={data?.touched[props?.fieldName] && Boolean(data?.errors[props?.fieldName]) ? errorStyle : {}}>
        {checklistByFieldName[props.fieldName].map(item =>
          <StyledCheckBox

            key={item}
            item={item}
            isChecked={clonedData.includes(item)}
            handleChange={() => handleItems(item)}
          />)}
      </div>
      <div>
        {data?.touched[props?.fieldName] && Boolean(data?.errors[props?.fieldName])
                    && <Typography style={
                      { color: '#d32f2f', fontSize: 12, marginLeft: 15 }}>{data?.errors[props?.fieldName]}</Typography>}
      </div>
    </>

  )
}

export default CheckBoxList


const checklistByFieldName = {
  offer: ['Määräaikaishuolto', 'Öljynvaihto', 'Jakohihnan vaihto', 'Jarruhuolto', 'Muu, mikä?'],
  contactBy: ['puhelimitse', 'sähköpostitse'],
}