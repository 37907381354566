import React from 'react'
import { Grid } from '@mui/material'
import Worker from '../../../styles/css/icons/huolto/icons8-engineer-80.png'
import clock from '../../../styles/css/icons/huolto/icons8-countdown-clock-80.png'
import face from '../../../styles/css/icons/huolto/icons8-happy-80.png'
import AppText from '../../../components/commons/AppText'
import InfoIconBox from './InfoIconBox'
import { FontSize } from '../../../components/utils/ConstObj'
import LevelOneTitle from '../../../components/commons/LevelOneTitle'
import LevelTwoTitle from '../../../components/commons/LevelTwoTitle'

const Index = ({ isSmall }) => {

  const text = 'Meidän tehtävämme on palvella sinua. Kun etsit osaavaa ja luotettavaa huolto- tai korjaamopalvelua autollesi olemme oikea valinta. Tunnemme ja osaamme korjata hyvin lähes kaikki automerkit. Käyttämämme varaosat ovat useimmiten samoja tai laadultaan vastaavia kuin eri automerkkien alkuperäisosat. Myös kaikki huollossa tarvittavat tarvikkeet, kuten öljyt, nesteet ja hoitoaineet ovat hinta/laatusuhteeltaan korkeatasoisia. Myönnämme luonnollisesti tehdylle työlle ja käyttämillemme varaosille aina takuun.'
  return (
    <div style={{ marginBottom: isSmall ? '100px' : '150px' }}>
      <LevelOneTitle isSmall={isSmall} label={'Tietoa meistä'}/>
      <Grid container columns={8}>
        <Grid item lg={4} sx={{ marginBottom: '50px' }}>
          <LevelTwoTitle label={'Tehtävämme'} isSmall={isSmall}/>
          <AppText text={text} fontSize={isSmall ? FontSize.h7 : FontSize.h6} color={'#54595f'}/>
        </Grid>
        <Grid item lg={4}>
          <Grid container
            direction="row"
            justifyContent="space-around"
            alignItems="center">
            <InfoIconBox text={'Ammattitaitoinen henkilökunta'} icon={Worker} isSmall={isSmall}
              title={'Uskottava'}/>
            <InfoIconBox text={'Sovitut työt hoidetaan aikataulun mukaisesti'} icon={clock}
              isSmall={isSmall}
              title={'Luotettava'}/>
            <InfoIconBox text={'Joustava, nopea ja ystävällinen palvelu'} icon={face} isSmall={isSmall}
              title={'Palveleva'}/>
          </Grid>

        </Grid>
      </Grid>
    </div>
  )
}

export default Index