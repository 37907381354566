import React from 'react'
import DrawerAppBar from '../../components/Header/DrawerAppBar'
import ScreenSizeWrapper from '../../components/utils/ScreenSizeWrapper'
import CarCleanF from '../../styles/css/icons/pesu/c-car_f.png'
import CarCleanD from '../../styles/css/icons/pesu/c-car-d.png'
import BlueHomeD from '../../styles/css/icons/drawer/blue-home-d.png'
import BlueHomeF from '../../styles/css/icons/drawer/blue-home-f.png'
import BlueEuroD from '../../styles/css/icons/drawer/blue-euro-d.png'
import BlueEuroF from '../../styles/css/icons/drawer/blue-euro-f.png'
import BlueInfoD from '../../styles/css/icons/drawer/blue-info-d.png'
import BlueInfoF from '../../styles/css/icons/drawer/blue-info-f.png'
import logo from '../../styles/css/icons/ED-pesu.png'
import { Element as ScrollElement } from 'react-scroll'
import MainSection from './MainSection'
import PesuSection2 from './PesuSection2'
import PesuSection3 from './PesuSection3'
import PesuSection4 from './PesuSection4'

const Index = () => {
  const navObjItems = [
    { id: 'etusivu', name: 'Etusivu', icon: BlueHomeD, chosenIcon: BlueHomeF },
    { id: 'pesupalvelut', name: 'Pesupalvelut', icon: CarCleanD, chosenIcon: CarCleanF },
    { id: 'pesuhinnasto', name: 'Pesuhinnasto', icon: BlueEuroD, chosenIcon: BlueEuroF },
    { id: 'yhteystiedot', name: 'Yhteystiedot', icon: BlueInfoD, chosenIcon: BlueInfoF },
  ]
  return (
    <div>
      <ScreenSizeWrapper Wrapper={DrawerAppBar}
        navObjItems={navObjItems}
        color={'blue'}
        colors={{
          bg: '#008dbc',
          font: '#000'
        }}
        phone={''}
        logo={logo}/>
      <ScrollElement name={navObjItems['0'].id}>
        <ScreenSizeWrapper Wrapper={MainSection}/>
      </ScrollElement>
      <ScrollElement name={navObjItems['1'].id}>
        <ScreenSizeWrapper Wrapper={PesuSection2}/>
      </ScrollElement>
      <ScrollElement name={navObjItems['2'].id}>
        <ScreenSizeWrapper Wrapper={PesuSection3}/>
      </ScrollElement>
      <ScrollElement name={navObjItems['3'].id}>
        <ScreenSizeWrapper Wrapper={PesuSection4}  size={'false'}/>
      </ScrollElement>
    </div>
  )
}

export default Index