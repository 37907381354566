import React, { useEffect } from 'react'
import { Container, Grid, Toolbar } from '@mui/material'
import LargeButton from '../../components/commons/LargeButton'
import ListIcon from '../../styles/css/icons/list.png'
import OutIcon from '../../styles/css/icons/out.png'
import { useRouter } from '../../components/utils/useRoutes'
import DrawerAppBar from '../../components/Header/DrawerAppBar'
import logo from '../../styles/css/icons/ED-pesu.png'
import ScreenSizeWrapper from '../../components/utils/ScreenSizeWrapper'
import { clearAllLocalStorage, userAuthentication } from '../../services/methods'

const Index = () => {
  const route = useRouter()

  const logout = () => {
    clearAllLocalStorage()
    route.push('/')
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await userAuthentication()
        console.log('------xxoxx------', user)
        if (user === null) {
          route.push('/edautogrouplogin')
        }
      } catch (error) {
        // Handle error if userAuthentication fails
        console.error('Error in user authentication:', error)
      }
    }

    fetchData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <ScreenSizeWrapper Wrapper={DrawerAppBar}
        navObjItems={[]}
        color={'red'}
        colors={{
          bg: '#008dbc',
          font: '#000'
        }}
        phone={''}
        logo={logo}/>
      <Container maxWidth="xl">
        <Toolbar />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: 'transparent' }}
        >
          <LargeButton key={'tarjoukset'} icon={ListIcon} text={'Tarjouspyynnöt'}
            actionClick={() => route.push('/edautogrouptarjoukset')}/>
          <LargeButton key={'palautteet'} icon={ListIcon} text={'Palautteet'}
            actionClick={() => route.push('/edautogrouppalautteet')}/>
          <LargeButton key={'logout'} icon={OutIcon} text={'Kirjaudu ulos'}
            actionClick={() => logout()}/>
        </Grid>
      </Container>
    </div>
  )
}

export default Index