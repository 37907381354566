import React from 'react'
import IconBox from '../../../components/commons/IconBox'
import { Grid } from '@mui/material'


import CarIcon from '../../../styles/css/icons/huolto/icons8-request-service-80.png'
import Doctor from '../../../styles/css/icons/huolto/icons8-doctor-80.png'
import Fan from '../../../styles/css/icons/huolto/icons8-fan-80.png'
import Brake from '../../../styles/css/icons/huolto/icons8-brake-warning-80.png'
import Repair from '../../../styles/css/icons/huolto/icons8-car-service-80.png'
import Automatic from '../../../styles/css/icons/huolto/icons8-automation-80.png'
import Oil from '../../../styles/css/icons/huolto/icons8-engine-oil-80.png'

import Tools from '../../../styles/css/icons/huolto/icons8-tools-80.png'
import { Sizes } from '../../../components/utils/Enums'
import LevelOneTitle from '../../../components/commons/LevelOneTitle'

const Index = ({ screenSize }) => {

  const isSmall = [Sizes['XS'], Sizes['SM'], Sizes['MD']].includes(screenSize)
  const font = isSmall ? 18 : 24

  const data = [
    { id: 1, text: 'Määräaikaishuollot', icon: CarIcon, font: font },
    { id: 2, text: 'Diagnostiikkapalvelut', icon: Doctor, font: font },
    { id: 3, text: 'Ilmastointihuolto', icon: Fan, font: font },
    { id: 4, text: 'Jarruhuollot', icon: Brake, font: font },
    { id: 5, text: 'Katsastus Huollot', icon: Repair, font: font },
    { id: 6, text: 'Öljynvaihto', icon: Oil, font: font },
    { id: 7, text: 'Alustakorjaukset', icon: Tools, font: font },
    { id: 8, text: 'Jakohihnan Vaihdot', icon: Automatic, font: font },
  ]

  return (
    <div style={{ marginBottom: isSmall ? '100px' : '150px' }}>
      <LevelOneTitle isSmall={isSmall} label={'Autokorjaamomme palvelut'}/>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center">
        {data.map(t => (
          <IconBox text={t.text} icon={t.icon} fontSize={t.font} isSmall={isSmall} key={t.id}/>
        ))}
      </Grid>
    </div>

  )
}

export default Index