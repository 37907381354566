import React from 'react'
import { Container, Grid, keyframes, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { useRouter } from '../../../components/utils/useRoutes'
import SectionButton from './SectionButton'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { FontSize } from '../../../components/utils/ConstObj'

import HUOLTOLOGO from '../../../styles/css/icons/ED.png'
import PESULOGO from '../../../styles/css/icons/ED-pesu.png'
import DividerBackground from '../../../components/commons/DividerBackground'

const Index = ({ isSmall }) => {

  const StyledTypo = styled(Typography)(() => ({
    fontFamily: 'PoppinsBold',
    fontSize: isSmall ? FontSize.h3 : 100,
    color: '#d008dbc',
    borderRadius: 50,
    textShadow: '0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #fff, 0 0 30px #FFF, 0 0 40px #FFF, 0 0 55px #FFF, 0 0 75px #FFF',
    textTransform: 'none',
    position: 'absolute',
    top: 100,
  }))


  const Background2 = keyframes`
        0% {
            transform: scale(0.95) translateY(0);
            transform-origin: 50% 16%;
        }
        100% {
            transform: scale(1) translateY(-15px);
            transform-origin: top;
        }
       
    `

  const Title = () =>  <StyledTypo>ED Auto Group Oy</StyledTypo>
  const route = useRouter()
  return (
    <Container  maxWidth={false} >
      <DividerBackground />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        height: `${window.innerHeight}px`,
        width: '100%',
        zIndex: 100,
        animation: `${Background2} 5s ease-out both`,
        backgroundSize: 'cover' }} >

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center">
          {Title()}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: 'transparent' }}
          >
            <SectionButton
              isSmall={isSmall}
              key={'autohuolto'}
              sx={{ paddingTop: isSmall? 3 : 5 }}
              icon={HUOLTOLOGO}
              text={'ED Autohuolto'}
              actionClick={() => route.push('/autohuolto')}/>
            <SectionButton
              isSmall={isSmall}
              key={'autopesu'}
              icon={PESULOGO}
              text={'ED Autopesu'}
              actionClick={() => route.push('/autopesu')}/>
          </Grid>
        </Stack>

      </Box>
    </Container>
  )
}

export default Index