import React from 'react'
import { Grid } from '@mui/material'
import CarClean from '../../../styles/css/icons/pesu/car-clean-1.png'
import { Sizes } from '../../../components/utils/Enums'
import LevelOneTitle from '../../../components/commons/LevelOneTitle'
import IconBox from '../../../components/commons/IconBox'
import Shower from '../../../styles/css/icons/pesu/icons8-shower-100.png'
import Vacuum from '../../../styles/css/icons/pesu/icons8-vacuum-cleaner-80.png'
import Perfume from '../../../styles/css/icons/pesu/icons8-perfume-80.png'
import Engine from '../../../styles/css/icons/pesu/icons8-engine-80.png'
import Leather from '../../../styles/css/icons/pesu/icons8-leather-100.png'
import Dog from '../../../styles/css/icons/pesu/icons8-year-of-dog-80.png'
import Shine from '../../../styles/css/icons/pesu/icons8-shine-80.png'


const Index = ({ screenSize }) => {

  const isSmall = [Sizes['XS'], Sizes['SM'], Sizes['MD']].includes(screenSize)
  const font = isSmall ? 18 : 24

  const data = [
    { id: 1, text: 'Käsinpesu', icon: CarClean, font: font },
    { id: 2, text: 'Tehopesu', icon: Shower, font: font },
    { id: 3, text: 'Sisäpuhdistus', icon: Vacuum, font: font },
    { id: 4, text: 'Normaalivahaus', icon: Shine, font: font },
    { id: 5, text: 'Kovavahaus', icon: Shine, font: font },
    { id: 6, text: 'Kevytpinnoite', icon: Shine, font: font },
    { id: 7, text: 'Nahkapenkkien suojakäsittely', icon: Leather, font: font },
    { id: 8, text: 'Hajun poisto otsonoinnilla', icon: Perfume, font: font },
    { id: 9, text: 'Moottorin pesu', icon: Engine, font: font },
    { id: 10, text: 'Eläinten karvojen poisto', icon: Dog, font: font },

  ]

  return (
    <div style={{ marginBottom: isSmall ? '100px' : '150px' }}>
      <LevelOneTitle isSmall={isSmall} label={'Autopesumme palvelut'}/>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center">
        {data.map(t => (
          <IconBox
            text={t.text}
            icon={t.icon}
            fontSize={t.font}
            key={t.id}
            isSmall={isSmall}/>
        ))}
      </Grid>
    </div>

  )
}

export default Index